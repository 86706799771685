.topnav {
  .navbar-nav {
    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 18px;
      color: $edc-text;

      i {
        font-size: 17px;
      }
    }

    .dropdown-item {
      color: $edc-text;
    }
  }

  .arrow-down {
    margin-left: -10px;
  }
}
