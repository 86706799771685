.color-primary {
  color: $primary;
}

.color-text {
  color: $gray-700;
}

.color-grey {
  color: $gray-500;
}

.color-primary-light {
  color: black;
}

.color-secondary {
  color: $secondary;
}

.color-success {
  color: $success;
}

.color-info {
  color: $info;
}

.color-danger {
  color: $danger;
}

.color-warning {
  color: $warning;
}

.color-purple {
  color: #7a57ba;
}

.color-pink {
  color: pink;
}

.bg-formation {
  color: #000000;
}

.color-table-secondary {
  color: #80cbc4;
}

.color-sort {
  color: white;
}

.bg-danger {
  background-color: rgba(#a93631, 0.8) !important;
}

.bg-success {
  background-color: rgba(#388563, 0.8) !important;
}

.bg-formation-valid {
  background-color: #f5f9fd !important;
}

.bg-soft-warning {
  background-color: #e9cd46 !important;
  color: white !important;
}

.header-column-title {
  background-color: $primary !important;
  color: $white !important;
}

.bg-risk-very-good {
  background-color: $risk-very-good;
  color: $white;
}

.bg-risk-good {
  background-color: $risk-good;
  color: $white;
}

.bg-risk-medium {
  background-color: $risk-medium;
  color: $white;
}

.bg-risk-bad {
  background-color: $risk-bad;
  color: $white;
}

.bg-risk-not-concerned {
  background-color: $risk-not-concerned;
  color: $white;
}

td {
  vertical-align: middle !important
}
