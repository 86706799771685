.modal-body-row {
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 15px;

  &__title {
    font-weight: 500;
    font-size: 15px;
  }

  &__content {
    font-size: 15px;
  }
}
