//
// _menu.scss (EDC version)
//

.vertical-menu {
    .logo .logo-lg {
        img {
            height: 60px;
        }
    }
}

// Enlarge menu
.vertical-collpsed {
    .logo {
        span.logo-sm {
            img {
                height: 35px;
            }
        }
    }
}

#sidebar-menu ul li a {
    padding: 0.6rem 0.5rem;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
    color: #ffffff !important;
    background-color: #597887 !important;

    i {
        color: white !important;
    }
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
    width: 260px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
    width: 320px !important;
}
