td.cell-hidden {
  color: #6c6c6c;
}

.badge-cartography {
  padding: 1em 1.5em !important;
  white-space: normal !important;
  max-width: 180px !important;
}

.text-cartography {
  padding: 0.5em 1em !important;
  max-width: 200px !important;
  min-width: 120px !important;
  word-wrap: break-word !important;
  font-size: 85%;
  cursor: pointer;
  min-height: 40px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  color: white;

  &::before {
    content: "+";
    position: absolute;
    left: 5px;
    top: 0;
    color: #495057;
  }
}

.text-cartography-read {
  padding: 0.5em 1em !important;
  max-width: 200px !important;
  min-width: 120px !important;
  word-wrap: break-word !important;
  font-size: 85%;
  min-height: 40px;
  text-align: center;
  margin: 0 auto;
  color: white;
}

.action-wrapper {
  max-width: 300px !important;
}

.domaine-text {
  color: rgb(79, 142, 203);
}
