.ql-editor {
  font-size: $font-size-root;

  p, li {
    color: $gray-600;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #495057;
  }
}
