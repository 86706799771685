.table {
  color: $edc-text;
}

// titres des tableaux
.table-card-list th,
.table th {
  color: #a08073;
}

.table-with-vertical-scroll {
  height: 60vh;
  overflow: auto;

  thead {
    position: sticky;
    top: 0 ;
    z-index: 1;

    th {
      position: sticky;
      top:0;
    }
  }
}
