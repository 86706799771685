.btn-caret {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  text-align: center;
  border: none;
  background-color: $primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &__left {
    position: absolute;
    top: 55%;
    left: 0;
  }

  &__right {
    position: absolute;
    top: 55%;
    right: 0;
  }

  &:hover {
    background-color: $link-hover-color;
  }
}

.btn-grey-light {
  background-color: $secondary-light;
  color: $dark;
}
