//
// authentication.scss (EDC version)
//


// authentication

.authentication-bg {
  background-color: $auth-bg;

  .login-subtitle {
    color: $edc-text !important;
  }

  .forgot-link {
    color: #bcc6ce !important;
  }

  label {
    color: $edc-text;
  }

  .auth-logo {
    .logo {
      margin: 0px auto;
      height: 130px;
    }
  }
}



