body[data-layout="horizontal"] {
  .navbar-header {
    .logo-lg {
      img {
        height: 60px;
      }
    }
  }

  .page-content {
    margin-top: 130px;
  }
}

@media (max-width: 991.98px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 30px;
    }
  }
}

